













































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  ParteDetalleModel,
  ParteModel,
} from "@/entidades/Plantacion/Partes/ParteModel";
import moment from "moment";
import { LaborModel } from "@/entidades/Plantacion/Partes/LaborModel";
import { Mutation, State, Action } from "vuex-class";
import { Convertir64, _arrayBufferToBase64 } from "@/plugins/Util";
import Alerta from "@/entidades/Sistema/Alerta";
//@ts-ignore
//document.getElementById('canvas')?.style.display ='none';

//@ts-ignore

const constraints = {
  audio: false,
  video: {
    width: 1280,
    height: 720,
  },
};
@Component({
  name: "TomarFoto",
})
export default class CierreLabor extends Vue {
  @Action("changeLoading", { namespace: "sistemaModule" })
  changeLoading!: Function;
  @Action("changeAlerta", { namespace: "sistemaModule" })
  changeAlerta!: Function;
  @Prop({ type: Number, required: true }) id!: number;
  imagenLabor: any = null;
  image: any = null;
  streamGlobal: any;
  validarCamara: boolean = false;
  //#endregio
  async initComponent() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.handleSuccess(stream);
      //@ts-ignore
      this.validarCamara = true;
    } catch (e) {
      this.changeAlerta(
        new Alerta(
          `No se encontro camara en dispositivo ${e}`,
          true,
          "danger",
          3000
        )
      );
      this.validarCamara = false;
    }
  }
  mounted() {
   // this.initComponent();
  }
  tomarFoto() {
    let video = document.getElementById("video");
    let canvas = document.getElementById("canvas");

    console.log("video", video);
    console.log("canvas", canvas);
    //@ts-ignore
    var context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, 640, 480);

    //@ts-ignore
    console.log("IMAGENbase64", canvas.toDataURL());
     //@ts-ignore
    this.image =  canvas.toDataURL();
  }

  activarCargarFoto()
  {
    this.validarCamara = false;
  }
  activarTomaFoto()
  {
    this.initComponent();
  }
  guardar()
  {
      console.log('entramos al metodo')
        this.$emit('guardarImagen',this.image);
   
  }
  handleSuccess(e: any) {
    let video = document.getElementById("video");
    let canvas = document.getElementById("canvas");
    this.streamGlobal = e;
    //@ts-ignore
    window.stream = e;
    //@ts-ignore
    video.srcObject = e;
  }

  handleImage(e:any)
    {
        const selectImagen = e;
        this.imgToBase64(selectImagen).then(c=>{
           this.image = c;
        }); 
    }

    async imgToBase64(data:File){
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(data)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }
}
